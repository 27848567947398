fieldset {
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  
  [class*="loader-"] {
    display: inline-block;
    width: 1em;
    height: 1em;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;
  }
  
  .loader-37 {
    border-right: 0.1em solid currentcolor;
    border-radius: 100%;
    -webkit-animation: loader-37 800ms linear infinite;
    animation: loader-37 800ms linear infinite;
  }
  .loader-37:before,
  .loader-37:after {
    content: "";
    width: 0.8em;
    height: 0.8em;
    display: block;
    position: absolute;
    top: calc(50% - 0.4em);
    left: calc(50% - 0.4em);
    border-left: 0.08em solid currentcolor;
    border-radius: 100%;
    animation: loader-37 400ms linear infinite reverse;
  }
  .loader-37:after {
    width: 0.6em;
    height: 0.6em;
    top: calc(50% - 0.3em);
    left: calc(50% - 0.3em);
    border: 0;
    border-right: 0.05em solid currentcolor;
    -webkit-animation: none;
    animation: none;
  }
  
  @-webkit-keyframes loader-37 {
    from {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    to {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
  
  @keyframes loader-37 {
    from {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    to {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
  